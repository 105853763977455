<template>
    <chart-of-account-wrapper>
        <template v-slot:child>
            <manage-account-header />
            <div class="w-90 center mt4">
                <div class="flex justify-between items-center manageHeader secPad">
                    <div class="b w-50">Account</div>
                    <div class="b w-50">Description</div>
                    <div class="b">
                        <!-- Action -->
                    </div>
                </div>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Cost of Sales</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'purchases', 
                                    title: 'Purchases' 
                                } 
                            }">Purchases</router-link>
                        </div>
                        <div class="w-50 tl">Purchases of products and services</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'commissions', 
                                    title: 'Commision on Purchases' 
                                } 
                            }">Commision on Purchases</router-link>
                        </div>
                        <div class="w-50 tl">Commisions paid from Purchases</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'vats', 
                                    title: 'VAT on Purchases' 
                                } 
                            }">VAT on Purchases</router-link>
                        </div>
                        <div class="w-50 tl">Tax and VAT paid on Purchases</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'other-purchase-costs', 
                                    title: 'Other Purchase Cost' 
                                } 
                            }">Other Purchase Cost</router-link>
                        </div>
                        <div class="w-50 tl">Other cost of purchase</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'shippings', 
                                    title: 'Shipping' 
                                } 
                            }">Shipping</router-link>
                        </div>
                        <div class="w-50 tl">Delivery and shipping fees on Purchases</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'raw-materials', 
                                    subtype: 'input_raw_materials', 
                                    title: 'Raw Materials' 
                                } 
                            }">Raw materials</router-link>
                        </div>
                        <div class="w-50 tl">Value of expenses spent on raw material and production accessories</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'direct-labour', 
                                    subtype: 'direct_labour', 
                                    title: 'Direct Labour' 
                                } 
                            }">Direct Labour</router-link>
                        </div>
                        <div class="w-50 tl">Labour cost used in production</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'direct-utility', 
                                    subtype: 'direct_utility', 
                                    title: 'Direct Utility' 
                                } 
                            }">Direct Utility</router-link>
                        </div>
                        <div class="w-50 tl">Utility cost used in production</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'services-rendered', 
                                    subtype: 'cost_of_goods_sold', 
                                    title: 'Cost of Service Rendered' 
                                } 
                            }">Cost of Service Rendered</router-link>
                        </div>
                        <div class="w-50 tl">Value of expenses for Cost of Service</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Expenses</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'advertisements', 
                                    title: 'Advertisement' 
                                } 
                            }">Advertisement</router-link>
                        </div>
                        <div class="w-50 tl">Branding, adverts, marketing and communication expenses</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'bad_depts', 
                                    title: 'Bad Debts' 
                                } 
                            }">Bad Debts</router-link>
                        </div>
                        <div class="w-50 tl">Provision for bad debts</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'bank-charges', 
                                    title: 'Bank Charges' 
                                } 
                            }">Bank Charges</router-link>
                        </div>
                        <div class="w-50 tl">Charges on bank transactions, cheque books, account maintenance fees, atms </div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'consumables', 
                                    title: 'Consumables' 
                                } 
                            }">Consumables</router-link>
                        </div>
                        <div class="w-50 tl">Items of small value with a likely useful life of less than 1 year. eg bulbs, extension boxes, </div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'equipment-depreciations', 
                                    title: 'Depreciation- Equipment' 
                                } 
                            }">Depreciation - Equipment</router-link>
                        </div>
                        <div class="w-50 tl">Depreciation expenses of equipment</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'furniture-depretiations', 
                                    title: 'Depreciation - Furniture & Fittings' 
                                } 
                            }">Depreciation - Furniture & Fittings</router-link>
                        </div>
                        <div class="w-50 tl">Depreciation expenses of furniture and fittings</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'machinery-depreciations', 
                                    title: 'Depreciation - Plant and Machinery' 
                                } 
                            }">Depreciation - Plant and Machinery</router-link>
                        </div>
                        <div class="w-50 tl">Depreciation expenses of plant and machinery</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'discount-allowed', 
                                    title: 'Discount Allowed' 
                                } 
                            }">Discount Allowed</router-link>
                        </div>
                        <div class="w-50 tl">Discounts given to customers on sale of products and services</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'electricity', 
                                    subtype: 'electricity', 
                                    title: 'Electricity' 
                                } 
                            }">Electricity</router-link>
                        </div>
                        <div class="w-50 tl">Electricity bill</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'entertainment', 
                                    subtype: 'entertainment', 
                                    title: 'Entertainment' 
                                } 
                            }">Entertainment</router-link>
                        </div>
                        <div class="w-50 tl">Water, sweets, snacks, food, drinks etc purchased for customers or visitors of the office</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'generator', 
                                    subtype: 'generator', 
                                    title: 'Generator - Diesel/Fuel' 
                                } 
                            }">Generator - Diesel/Fuel</router-link>
                        </div>
                        <div class="w-50 tl">Fuel or diesel expenses for the company's generator</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'gift-donations', 
                                    subtype: 'gift_received', 
                                    title: 'Gift and Donations' 
                                } 
                            }">Gift and Donations</router-link>
                        </div>
                        <div class="w-50 tl">Gift and donations given by the company</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'insurance', 
                                    subtype: 'business_insurance', 
                                    title: 'Insurance' 
                                } 
                            }">Insurance</router-link>
                        </div>
                        <div class="w-50 tl">Insurance expenses</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'internet', 
                                    subtype: 'internet', 
                                    title: 'Internet' 
                                } 
                            }">Internet</router-link>
                        </div>
                        <div class="w-50 tl">Expenses on internet data subscription</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'it-expenses', 
                                    subtype: 'website_software', 
                                    title: 'IT Expenses' 
                                } 
                            }">IT Expenses</router-link>
                        </div>
                        <div class="w-50 tl">Software subscriptions, computer anti-virus etc</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'periodicals', 
                                    subtype: 'periodicals', 
                                    title: 'Periodicals' 
                                } 
                            }">Periodicals</router-link>
                        </div>
                        <div class="w-50 tl">Newspaper, magazines and other periodicals purchased for the office</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'printing', 
                                    subtype: 'printing', 
                                    title: 'Printing' 
                                } 
                            }">Printing</router-link>
                        </div>
                        <div class="w-50 tl">Toners for printers, printing papers and other printing costs</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'professional-fees', 
                                    subtype: 'professional_business_fees', 
                                    title: 'Professional Fees' 
                                } 
                            }">Professional Fees</router-link>
                        </div>
                        <div class="w-50 tl">Payment for professional services eg Lawyers, accountants</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    subtype: 'rent', 
                                    title: 'Rent Expenses' 
                                } 
                            }">Rent Expenses</router-link>
                        </div>
                        <div class="w-50 tl">Monthly rent expense</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'repairs-maintenance', 
                                    subtype: 'maintenance_repairs', 
                                    title: 'Repairs and Maintenance' 
                                } 
                            }">Repairs and Maintenance</router-link>
                        </div>
                        <div class="w-50 tl">Repairs and maintenance of company asset</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'salary', 
                                    subtype: 'salary', 
                                    title: 'Staff Salary' 
                                } 
                            }">Staff Salary</router-link>
                        </div>
                        <div class="w-50 tl">Salaries paid to company staff</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'stationary', 
                                    subtype: 'home_office_cost', 
                                    title: 'Stationary' 
                                } 
                            }">Stationary</router-link>
                        </div>
                        <div class="w-50 tl">Office stationary eg pens, books, files, pins etc</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'telephone', 
                                    subtype: 'telephone', 
                                    title: 'Telephone' 
                                } 
                            }">Telephone</router-link>
                        </div>
                        <div class="w-50 tl">Telephone expenses (airtime)</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'meal-travels', 
                                    subtype: 'business_meals_travel_expense', 
                                    title: 'Business Meals & Travels' 
                                } 
                            }">Business Meals & Travels</router-link>
                        </div>
                        <div class="w-50 tl">Travel expenses, per diem and office meetings</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'transport', 
                                    subtype: 'transport', 
                                    title: 'Transport' 
                                } 
                            }">Transport</router-link>
                        </div>
                        <div class="w-50 tl">Transportation expenses</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'commission', 
                                    subtype: 'commission', 
                                    title: 'Commission' 
                                } 
                            }">Commission</router-link>
                        </div>
                        <div class="w-50 tl">Commission Expenses</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'interest', 
                                    subtype: 'interest_expense', 
                                    title: 'Interest Expense' 
                                } 
                            }">Interest Expense</router-link>
                        </div>
                        <div class="w-50 tl"></div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'trainings', 
                                    subtype: 'trainings', 
                                    title: 'Trainings' 
                                } 
                            }">Trainings</router-link>
                        </div>
                        <div class="w-50 tl">Training and education cost for Staff & Management</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'utility-cable-tv', 
                                    subtype: 'utility_cable_tv', 
                                    title: 'Utility - Cable TV' 
                                } 
                            }">Utility - Cable TV</router-link>
                        </div>
                        <div class="w-50 tl">Cable TV subscription</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'packaging', 
                                    subtype: 'packaging', 
                                    title: 'Packaging and Branding' 
                                } 
                            }">Packaging and Branding</router-link>
                        </div>
                        <div class="w-50 tl">Packaging and branding expenses</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'utility-water-disposal', 
                                    subtype: 'utility_water_and_disposal', 
                                    title: 'Utility - Waste Disposal' 
                                } 
                            }">Utility - Waste Disposal</router-link>
                        </div>
                        <div class="w-50 tl">Waste disposal expenses </div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'furniture-machinery', 
                                    subtype: 'furniture_equipment_machinery', 
                                    title: 'Furniture/Equipment/Machinery' 
                                } 
                            }">Furniture/Equipment/Machinery</router-link>
                        </div>
                        <div class="w-50 tl"></div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'employee-benefits', 
                                    subtype: 'employee_benefits', 
                                    title: 'Employee Benefits' 
                                } 
                            }">Employee Benefits</router-link>
                        </div>
                        <div class="w-50 tl"></div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'home-office-cost', 
                                    subtype: 'home_office_cost', 
                                    title: 'Home or Office Cost' 
                                } 
                            }">Home or Office Cost</router-link>
                        </div>
                        <div class="w-50 tl"></div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ 
                                name: 'Expenses', 
                                query: { 
                                    path: 'others', 
                                    subtype: 'others', 
                                    title: 'Other Expenses' 
                                } 
                            }">Other Expenses</router-link>
                        </div>
                        <div class="w-50 tl">Any other uncategorized expenses</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
            </div>
        </template>
    </chart-of-account-wrapper>
</template>
<script>
import ChartOfAccountWrapper from '../widgets/ChartOfAccountWrapper.vue'
import ManageAccountHeader from '../widgets/ManageAccountHeader.vue'
export default {
    name: 'ManageExpenses',
    components: { ManageAccountHeader, ChartOfAccountWrapper },
    setup() {
        return {}
    },
}
</script>
<style scoped>
.secPad div {
    align-items: flex-start !important;
}
</style>
